import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingReportFilter = _resolveComponent("LoadingReportFilter")!
  const _component_LoadingReportInfoDialog = _resolveComponent("LoadingReportInfoDialog")!
  const _component_Report = _resolveComponent("Report")!

  return (_openBlock(), _createBlock(_component_Report, {
    ref: "reportComponent",
    reportType: _ctx.reportType,
    reportTitle: "Отчет о погрузке РЖД"
  }, {
    reportSettings: _withCtx(({ loading, toggleInfoDialog }) => [
      _createVNode(_component_LoadingReportFilter, {
        onOnReport: _ctx.onReport,
        onOnDownload: _ctx.onDownload,
        onOnStop: _ctx.onStop,
        loading: loading,
        ref: "reportFilter"
      }, null, 8, ["onOnReport", "onOnDownload", "onOnStop", "loading"]),
      _createVNode(_component_LoadingReportInfoDialog, { toggleInfoDialog: toggleInfoDialog }, null, 8, ["toggleInfoDialog"])
    ]),
    _: 1
  }, 8, ["reportType"]))
}