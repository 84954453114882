
import { defineComponent } from "vue";
import Report from "./Report.vue";
import LoadingReportFilter from "./LoadingRZDReportFilter.vue";
import router from "@/router";
import { ReportType } from "./ReportType";
import LoadingReportInfoDialog from "@/components/info/LoadingReportInfoDialog.vue";

export default defineComponent({
  components: {
    Report,
    LoadingReportFilter,
    LoadingReportInfoDialog,
  },
  computed: {
    reportType(): any {
      return ReportType.LoadingRZDReport;
    },
  },
  methods: {
    onReport(params: any) {
      this.$refs.reportComponent.onReport(params);
    },
    onDownload(params: any) {
      this.$refs.reportComponent.onDownload(params);
    },
    onStop() {
      this.$refs.reportComponent.onStop();
    },
  },
  mounted() {
    const routeParams = router.currentRoute.value.params;
    if (routeParams.planId) {
      const model = {
        planId: parseInt(routeParams.planId as string),
        planDescription: routeParams.planDescription,
      };
      this.$refs.reportFilter.setModel(model);
    }
  },
});
